import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

import WigLoader from '@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader';

import '../css/wig-bundle.css';

import '@fortawesome/fontawesome-pro/css/all.css';

import fetchJsonp from 'fetch-jsonp';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '../components/Analytics';
import { useTracking } from 'react-tracking';

require('es6-promise').polyfill();

const InfoPage = (props) => {
  const {
    pageContext: {
      integratorData,
      contentMetaData,
      contentDataData,
      config,
      navResultData,
    },
  } = props;

  const siteConfig = config;

  const [apiKey, setApiKey] = useState(siteConfig.apiKey || props.smartfeedID);
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  // const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  // const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
  //   'ap-signals-session-id'
  // );

  // const { trackEvent } = useTracking(
  //   { page: 'Homepage', location: props.location, apiKey: props.apiKey },
  //   {
  //     dispatch: (data) =>
  //       trackAnalyticsEvent(
  //         data,
  //         cookies['ap-signals-user-id'],
  //         sessionIDValue,
  //         siteConfig
  //       ),
  //   }
  // );

  // useEffect(() => {
  //   trackAnalyticsPageView(
  //     props.location,
  //     cookies['ap-signals-user-id'],
  //     sessionIDValue,
  //     siteConfig
  //   );
  // }, []);

  useEffect(() => {
    if (apiKey) {
      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            'GetCompanyDetails' +
            '?id=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function(response) {
            return response.json();
          })
          .then(function(jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                '/CareerPage/' +
                'GetApplicantGetwayURL' +
                '?companyId' +
                '=' +
                apiKey,
              {
                timeout: 15000,
              }
            )
              .then(function(response) {
                return response.json();
              })
              .then(function(appgatway) {
                setVacanciesRouteLoading(false);
                setSmartfeedCompanyData(jsonList);
                setAppGatewayURL(appgatway);
              })
              .catch(function(ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, 'error');
              });
          })
          .catch(function(ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [apiKey]);

  const returnParentArray = (id) => {
    if (id) {
      if (
        navResultData &&
        navResultData.menu &&
        navResultData.menu.length > 0
      ) {
        let parentArrayData = navResultData.menu.filter(function(item) {
          return item.id === id;
        });

        return parentArrayData[0];
      } else {
        return {};
      }
    } else {
      return {};
    }
  };

  let pageParentDataArray = {};

  if (contentMetaData && contentMetaData.parent && contentMetaData.parent.id) {
    pageParentDataArray = returnParentArray(contentMetaData.parent.id);
  }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  // console.log(contentDataData, 'contentDataData');

  const [background, setBackground] = useState(
    'url(https://cdn2.accropress.com/84e34b84-ea17-469f-8739-76774d8e2032/media/global/4a56a6dd-ca3f-477a-81b8-c96631627301_full_screenshot_2022-10-20_at_15.16.23-modified.png)'
  );

  useEffect(() => {
    const getUrl = window.location.pathname;
    if (getUrl === '/homelessness-jobs') {
      setBackground(
        'url(https://cdn2.accropress.com/84e34b84-ea17-469f-8739-76774d8e2032/media/global/fd1af095-3587-46f8-8bcf-1fd4d184c729_full_homelessness-.young.adults-.models.004-modified.jpeg)'
      );
    }
    if (getUrl === '/early-years-jobs') {
      setBackground(
        'url(https://cdn2.accropress.com/84e34b84-ea17-469f-8739-76774d8e2032/media/global/1b9ecbad-9ee0-41e7-8778-d264dd64231f_full_sa.bromley.toddler.group.191121.114217-modified.jpeg)'
      );
    }
    if (getUrl === '/older-peoples-services-jobs') {
      setBackground(
        'url(https://cdn2.accropress.com/84e34b84-ea17-469f-8739-76774d8e2032/media/global/8915ad78-bf57-47a5-873f-c90a45c87912_full_sa.villa.adastra.care.home.andrew.king.190806.112844b-modified.jpeg)'
      );
    }
    if (getUrl === '/benefits') {
      setBackground(
        'url(https://cdn2.accropress.com/84e34b84-ea17-469f-8739-76774d8e2032/media/global/60a0f132-a067-4df7-ae2d-8ca7153a4fb2_full_screenshot_2022-11-01_at_14.47.20.png)'
      );
    }
    if (getUrl === '/modern-slavery-jobs') {
      setBackground(
        'url(https://cdn2.accropress.com/84e34b84-ea17-469f-8739-76774d8e2032/media/global/778c3248-34e9-4570-b0c7-5f6b0f94b29b_full_screenshot_2022-10-20_at_14.56.10-modified.png)'
      );
    }
    if (getUrl === '/employment-plus') {
      setBackground(
        'url(https://cdn2.accropress.com/84e34b84-ea17-469f-8739-76774d8e2032/media/085a0362-3123-41ed-8a36-8411b583733e/73d7d56f-9099-4608-aaa9-6f3ef8b84085_full_screenshot_2022-11-01_at_13.58.22.png)'
      );
    }
  }, [background]);

  return (
    <Layout
      // trackEvent={trackEvent}
      siteConfig={siteConfig}
      location={props.location}
      apiKey={apiKey}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      path="/"
      navResultData={navResultData}
      contentDataData={contentDataData}
      integratorData={integratorData}
      contentMetaData={contentMetaData}
      {...props}
    >
      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          <div
            className="infopage-background"
            style={{
              backgroundImage: `${background}`,
            }}
          >
            <div className="banner page">
              <div className="container">
                <div className="bannerWrapper">
                  <h1 className="h1-banner">
                    {contentDataData[0].children[1].value}
                  </h1>
                  <a
                    href={contentDataData[0].children[2].value}
                    className="btn vf-banner-search-btn"
                  >
                    {contentDataData[0].children[2].text}
                  </a>
                  <div className="mb-4"></div>
                  <div className="breadcrumb">
                    <Link className="text-danger" to="/">
                      Home
                    </Link>{' '}
                    / <span className="active">{contentMetaData.title}</span>
                  </div>
                  <div className="mb-4"></div>
                </div>
              </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#ffffff"
                fillOpacity="1"
                d="M0,192L80,186.7C160,181,320,171,480,170.7C640,171,800,181,960,165.3C1120,149,1280,107,1360,85.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
              ></path>
            </svg>
          </div>

          <div className="container">
            <div className="row justify-content-center vf-minus">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <WigLoader
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="1"
                  contentDataChild="0"
                  customDataArray={contentDataData[1].children[0].value}
                  contentDataChildChildChild="1"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                />
              </div>
            </div>
          </div>

          <div className="mb-5"></div>

          {/* <div className="p-3 mb-2 vf-primary-blue text-white">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <h1 className="vf-h1-title">
                {contentDataData[2].children[0].value}
              </h1>
              <a
                href={contentDataData[2].children[1].value}
                className="btn vf-search-now-btn"
              >
                {contentDataData[2].children[1].text}
                <img
                  className="vf-search-now-image"
                  src="/img/external-link-blue.svg"
                />
              </a>
            </div>
          </div> */}

          <div className="mb-5"></div>

          <div className="row justify-content-center offset-lg-2">
            <div className="row">
              {contentDataData[3].children[0].children.map((column, i) => (
                <div
                  key={i}
                  className="col-md-2-4 col-sm-6 col-xs-12 mb-5 vf-parent"
                >
                  <div className=" info-page">
                    <div className="card card-role">
                      <a href={column[2].value}>
                        <img
                          className="card-img-top"
                          src={column[0].value}
                          alt={column[1].value}
                        />
                      </a>
                      <a href={column[2].value} className="vf-atag-card-title">
                        {column[1].value}
                      </a>
                      <p className="card-text mb-5">{column[3].value}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-5"></div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  );
};

export default InfoPage;
